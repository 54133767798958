<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black" :data-task-id="taskId">
		<v-card>

			<v-card-title>{{taskId ? 'Edit' : 'Add'}} Task</v-card-title>

			<v-card-text v-if="taskData">

				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>

				<v-alert type="warning" color="error" border="left" class="my-3" v-if="!taskId && taskData.project && taskData.project.status==='closed'">
					This project is <strong>closed</strong>. Tasks will not show in work flow on closed projects. Change this project to hidden or open.
				</v-alert>

				<v-text-field v-if="!fieldsLocked('name')" v-model="taskData.name" :disabled="saving" dense label="Name *" min-length="3" hide-details class="my-3" outlined required></v-text-field>

				<!-- todo clientProjectGroups -->
				<project-picker
					v-if="!fieldsLocked('project_id')"
					label="Project*"
					placeholder="Select One..."
					v-model="taskData.project_id"
					class="my-3"
					:disabled="saving || !!taskId"
					outlined
					dense
					hide-details
				>
					<template v-slot:item="{item}">
						<span>{{item.name}}</span><span v-if="item.status == 'hidden'" class="ml-auto">hidden</span>
					</template>
					<template v-slot:selection="{item}">
						<span>{{item.name}}</span>
					</template>
				</project-picker>

				<task-group-picker
					v-if="!fieldsLocked('task_group_id') && taskData.project_id && !taskId"
					class="my-3"
					v-model="taskData.task_group_id"
					:project-id="taskData.project_id"
				></task-group-picker>

				<previous-tasks
					v-if="!lockedFields.previous_task_ids && taskData.task_group_id"
					:taskId="taskId"
					:taskGroupId="taskData.task_group_id"
					v-model="taskData.previous_task_ids"/>

				<div v-if="!lockedFields.required_days" class="d-flex my-3 input-group">
					<v-text-field v-model="taskData.required_days" :disabled="saving || !taskData.project_id" type="number" dense label="Required Days" outlined hide-details></v-text-field>
					<required-days-picker
						v-model="taskData.required_days"
						:timeline-due-date="taskData.timeline_due_date"
						:previous-task-ids="taskData.previous_task_ids"
						:project-id="taskData.project_id"
						v-slot="{ trigger }"
					>
						<v-btn color="primary" min-width="0" @click="trigger" :disabled="!taskData.project_id"><v-icon small>fad fa-calendar-alt</v-icon></v-btn>
					</required-days-picker>
				</div>

				<employee-picker v-if="!lockedFields.assigned_by_user_id" v-model="taskData.assigned_by_user_id" :disabled="!$store.getters.isAdminUser || saving"
								 label="Assigned By User"
								 class="my-3"
								 dense outlined hide-details>
				</employee-picker>

				<employee-picker v-if="!lockedFields.assigned_to_user_id" v-model="taskData.assigned_to_user_id" :disabled="saving"
								 label="Assigned To User" placeholder="Who receives this task"
								 class="my-3"
								 dense outlined hide-details>
				</employee-picker>

				<v-textarea v-if="!lockedFields.description" label="Description" v-model="taskData.description" :disabled="saving" outlined hide-details class="my-3" rows="3" auto-grow></v-textarea>

				<!-- todo other tasks -->
				<!-- <v-autocomplete v-model="taskData.parent_id" :items="otherTasks" label="Not a subtask" placeholder="Who receives this task" :disabled="saving" dense class="my-3" outlined hide-details></v-autocomplete> -->

				<template v-if="showAllFields">
					<v-menu v-if="!lockedFields.due_date" ref="dueDateMenu" v-model="dueDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="taskData.due_date" type="date" label="Due Date" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" clearable dense class="my-3" outlined hide-details></v-text-field>
						</template>
						<v-date-picker v-model="taskData.due_date" no-title scrollable color="primary" @input="dueDateMenu = false"></v-date-picker>
					</v-menu>

					<v-menu v-if="!lockedFields.complete_date" ref="completeDateMenu" v-model="completeDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="taskData.complete_date" type="date" label="Complete Date" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" clearable dense class="my-3" outlined hide-details></v-text-field>
						</template>
						<v-date-picker v-model="taskData.complete_date" no-title scrollable color="primary" @input="completeDateMenu = false"></v-date-picker>
					</v-menu>
				</template>

				<meta-input-list ref="metaFieldList" :model="taskData" model-type="Task" :disabled="saving"></meta-input-list>
			</v-card-text>

			<v-card-actions>
				<v-btn text v-if="!showAllFields && !fieldsLocked('due_date', 'complete_date')" @click="showAllFields=true">Show All Fields</v-btn>

				<div class="ml-auto">
					<v-btn :disabled="saving" @click="modal.trigger('close')" class="mr-3">Close</v-btn>
					<v-btn color="primary" :loading="saving" @click="submit">
						{{taskId ? 'Update' : 'Create'}}
					</v-btn>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import MetaInputList from "../metaInputList";
	import UserAvataaar from "../avataaars/user-avataaar";
	import { IdType } from "../../utils/IdType";
	import PreviousTasks from "../fields/previousTasks.vue";
	import ProjectPicker from "../fields/projectPicker.vue";
	import EmployeePicker from "../fields/employeePicker.vue";
	import TaskGroupPicker from '../fields/taskGroupPicker.vue';
	import requiredDaysPicker from "../fields/requiredDaysPicker.vue";
	import { EventBus } from '../../utils/EventBus';

	const TASK_FRAGMENT = `
		id
		name
		project {
			id
			status
		}
		project_id
		parent_id
		previous_task_ids
		completion_order
		status
		description
		task_group_id
		due_date @formatDate(format: "Y-m-d")
		required_days
		assigned_by_user_id
		assigned_to_user_id
		start_date
		complete_date @formatDate(format: "Y-m-d")
		current_status_date
		pending_status_date
		estimated_completion_date
		timeline_due_date @formatDate(format: "Y-m-d")
		creation_date
		_meta_values
	`;

	export default {
		name: "taskAddEdit",

		requiredFields: [
			'name',
			'project_id'
		],

		props: {
			modal: {
				type: Object,
				required: true,
			},
			
			// lockedProjectId: {
			// 	type: [Number, String],
			// },
			// previousTaskIds: {
			// 	type: Array,
			// },
			insertAfter: {
				type: [Number, String],
			},
			taskId: {
				type: IdType,
				default: 0,
			},
			populate: {
				type: Object,
				default: () => ({}),
			},
			lockedFields: {
				type: Object,
				default: () => ({
					project_id: true
				})
			}
		},
		components: {
			MetaInputList,
			UserAvataaar,
			PreviousTasks,
			ProjectPicker,
			EmployeePicker,
			TaskGroupPicker,
			requiredDaysPicker
		},
		data() {
			return {
				modalErrorMessage: false,
				saving: false,
				dueDateMenu: false,
				completeDateMenu: false,
				showAllFields: false,
				taskData: null,
			};
		},

		apollo: {
			taskDetails: {
				query: gql`
					query GetSingleTask($taskId: ID!) {
						taskDetails: task(id: $taskId) {
							${TASK_FRAGMENT}
						}
					}
				`,
				variables() {
					return {
						taskId: this.taskId,
					};
				},
				skip() {
					return !this.taskId;
				},
			}
		},

		computed: {
			populated() {
				return {
					...this.populate,
					required_days: 0,
					assigned_by_user_id: this.$store.getters.userId
				};
			},

			project_id() {
				return this.lockedProjectId || this.taskData.project_id || 0;
			},

			project() {
				return this.$db.getModel(
					"project",
					this.project_id ||
						this.lockedProjectId ||
						(this.task && this.task.project_id) ||
						0
				);
			},
		},

		destroyed() {
			this.$db.removeHandle("task-add-edit");
		},

		watch: {
			taskDetails(to) {
				this.copyData(to);
			},
			taskId: {
				immediate: true,
				handler(to) {
					if (!to) {
						this.copyData({
							previous_task_ids: [],
							...this.populated
						});
					}
				},
			},
		},

		methods: {
			copyData(graphQlData = {}) {
				let copyManyFn = (props, subKey = "") => {
					let source = graphQlData;
					if (subKey) {
						source = source[subKey] || {};
					}

					let result = {};
					for (let i of props) {
						result[i] = source.hasOwnProperty(i)
							? typeof source[i] == "object"
								? JSON.parse(JSON.stringify(source[i]))
								: source[i]
							: null;
					}

					return result;
				};

				let projectInfo = {};

				this.taskData = {
					...copyManyFn([
						"name",
						"parent_id",
						"previous_task_ids",
						"completion_order",
						"status",
						"description",
						"due_date",
						"required_days",
						"assigned_by_user_id",
						"assigned_to_user_id",
						"start_date",
						"complete_date",
						"current_status_date",
						"pending_status_date",
						"estimated_completion_date",
						"timeline_due_date",
						"creation_date",
						"_meta_values",
						"project_id",
						"task_group_id"
					]),
					...projectInfo,
				};

				if (this.taskId) {
					this.taskData.id = this.taskId;
				}
			},

			fieldsLocked(...fields) {
				return fields.every(f => {
					if (!this.taskId && !this.populated.hasOwnProperty(f) && this.$options.requiredFields.includes(f)) {
						return false;
					}

					return this.lockedFields[f];
				});
			},

			formModel() {
				let model = {
					name: this.taskData.name,
					// project_id: this.taskData.project_id,
					parent_id: this.taskData.parent_id,
					previous_task_ids: (this.taskData.previous_task_ids || []).join(","),
					required_days: parseInt(this.taskData.required_days),
					due_date: this.taskData.due_date,
					assigned_by_user_id: this.taskData.assigned_by_user_id,
					assigned_to_user_id: this.taskData.assigned_to_user_id,
					description: this.taskData.description,
					complete_date: this.taskData.complete_date,
					insertAfter: this.insertAfter, //todo ???
					// status: this.taskData.status
				};

				if(this.taskId) {
					Object.assign(model, { id: this.taskId });
				} else {
					Object.assign(model, { project_id: this.taskData.project_id });
				}

				for (let field in model) {
					if (!model[field]) {
						// prevent editing of complete_date
						if ("complete_date due_date".indexOf(field) >= 0) {
							// allow to un-complete a task, or remove a task's due date, by setting the value as null:
							model[field] = null;
						}
						/* else {
							delete model[field];
						} */
					}
				}

				Object.assign(model, this.$refs.metaFieldList.getFields());

				if (!this.taskId && this.taskData.task_group_id) {
					model.task_group_id = this.taskData.task_group_id;
				}

				return model;
			},

			submit() {
				this.saving = true;

				// validate
				let model = this.formModel();

				let e = {
					model: model,
					cancel: false,
					errorMsg: false,
				};
				this.modal.trigger("validate", e);
				if (e.cancel) {
					this.saving = false;
					this.modalErrorMessage = e.errorMsg;
					return;
				}


				// gql
				let payload = {
					variables: {
						data: this.formModel()
					},
					update: (store, { data: { result } }) => {
						this.saving = false;
						this.modal.trigger("save close", result);

						this.$db.addModels("task", result);
						// if (this.taskId) {
						// 	EventBus.$emit('task/update');
						// } else {
						// 	EventBus.$emit('task:new');
						// }
					}
				}

				// if editing
				if(this.taskId) {
					payload.mutation = gql`
						mutation ($data: UpdateTaskArgs!) {
							result: updateTask(data: $data) {
								${TASK_FRAGMENT}
							}
						}
					`;
				}
				// if new task
				else {
					payload.mutation = gql`
						mutation ($data: CreateTaskArgs!) {
							result: createTask(data: $data) {
								${TASK_FRAGMENT}
							}
						}
					`;
				}

				this.$apollo
					.mutate(payload)
					.catch((error) => {
						console.error("error saving task", error);
						this.modalErrorMessage = "There was a problem saving the data.";
						this.saving = false;
						// let errors = this.$insight.helpers.getRequestErrors(xhr);
						// errors = this.$insight.helpers.flattenRequestErrors(errors);

						// this.modalErrorMessage = errors.join("<br>");
					})

			},
		},
	};
</script>