var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"min-width":"300","max-width":"600","persistent":"","noClickAnimation":"","overlay-opacity":"0.9","overlay-color":"black","data-task-id":_vm.taskId}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.taskId ? 'Edit' : 'Add')+" Task")]),(_vm.taskData)?_c('v-card-text',[(_vm.modalErrorMessage)?_c('v-alert',{staticClass:"my-3",attrs:{"color":"red","border":"bottom"},domProps:{"innerHTML":_vm._s(_vm.modalErrorMessage)}}):_vm._e(),(!_vm.taskId && _vm.taskData.project && _vm.taskData.project.status==='closed')?_c('v-alert',{staticClass:"my-3",attrs:{"type":"warning","color":"error","border":"left"}},[_vm._v(" This project is "),_c('strong',[_vm._v("closed")]),_vm._v(". Tasks will not show in work flow on closed projects. Change this project to hidden or open. ")]):_vm._e(),(!_vm.fieldsLocked('name'))?_c('v-text-field',{staticClass:"my-3",attrs:{"disabled":_vm.saving,"dense":"","label":"Name *","min-length":"3","hide-details":"","outlined":"","required":""},model:{value:(_vm.taskData.name),callback:function ($$v) {_vm.$set(_vm.taskData, "name", $$v)},expression:"taskData.name"}}):_vm._e(),(!_vm.fieldsLocked('project_id'))?_c('project-picker',{staticClass:"my-3",attrs:{"label":"Project*","placeholder":"Select One...","disabled":_vm.saving || !!_vm.taskId,"outlined":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),(item.status == 'hidden')?_c('span',{staticClass:"ml-auto"},[_vm._v("hidden")]):_vm._e()]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}],null,false,855890390),model:{value:(_vm.taskData.project_id),callback:function ($$v) {_vm.$set(_vm.taskData, "project_id", $$v)},expression:"taskData.project_id"}}):_vm._e(),(!_vm.fieldsLocked('task_group_id') && _vm.taskData.project_id && !_vm.taskId)?_c('task-group-picker',{staticClass:"my-3",attrs:{"project-id":_vm.taskData.project_id},model:{value:(_vm.taskData.task_group_id),callback:function ($$v) {_vm.$set(_vm.taskData, "task_group_id", $$v)},expression:"taskData.task_group_id"}}):_vm._e(),(!_vm.lockedFields.previous_task_ids && _vm.taskData.task_group_id)?_c('previous-tasks',{attrs:{"taskId":_vm.taskId,"taskGroupId":_vm.taskData.task_group_id},model:{value:(_vm.taskData.previous_task_ids),callback:function ($$v) {_vm.$set(_vm.taskData, "previous_task_ids", $$v)},expression:"taskData.previous_task_ids"}}):_vm._e(),(!_vm.lockedFields.required_days)?_c('div',{staticClass:"d-flex my-3 input-group"},[_c('v-text-field',{attrs:{"disabled":_vm.saving || !_vm.taskData.project_id,"type":"number","dense":"","label":"Required Days","outlined":"","hide-details":""},model:{value:(_vm.taskData.required_days),callback:function ($$v) {_vm.$set(_vm.taskData, "required_days", $$v)},expression:"taskData.required_days"}}),_c('required-days-picker',{attrs:{"timeline-due-date":_vm.taskData.timeline_due_date,"previous-task-ids":_vm.taskData.previous_task_ids,"project-id":_vm.taskData.project_id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var trigger = ref.trigger;
return [_c('v-btn',{attrs:{"color":"primary","min-width":"0","disabled":!_vm.taskData.project_id},on:{"click":trigger}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-calendar-alt")])],1)]}}],null,false,1467322402),model:{value:(_vm.taskData.required_days),callback:function ($$v) {_vm.$set(_vm.taskData, "required_days", $$v)},expression:"taskData.required_days"}})],1):_vm._e(),(!_vm.lockedFields.assigned_by_user_id)?_c('employee-picker',{staticClass:"my-3",attrs:{"disabled":!_vm.$store.getters.isAdminUser || _vm.saving,"label":"Assigned By User","dense":"","outlined":"","hide-details":""},model:{value:(_vm.taskData.assigned_by_user_id),callback:function ($$v) {_vm.$set(_vm.taskData, "assigned_by_user_id", $$v)},expression:"taskData.assigned_by_user_id"}}):_vm._e(),(!_vm.lockedFields.assigned_to_user_id)?_c('employee-picker',{staticClass:"my-3",attrs:{"disabled":_vm.saving,"label":"Assigned To User","placeholder":"Who receives this task","dense":"","outlined":"","hide-details":""},model:{value:(_vm.taskData.assigned_to_user_id),callback:function ($$v) {_vm.$set(_vm.taskData, "assigned_to_user_id", $$v)},expression:"taskData.assigned_to_user_id"}}):_vm._e(),(!_vm.lockedFields.description)?_c('v-textarea',{staticClass:"my-3",attrs:{"label":"Description","disabled":_vm.saving,"outlined":"","hide-details":"","rows":"3","auto-grow":""},model:{value:(_vm.taskData.description),callback:function ($$v) {_vm.$set(_vm.taskData, "description", $$v)},expression:"taskData.description"}}):_vm._e(),(_vm.showAllFields)?[(!_vm.lockedFields.due_date)?_c('v-menu',{ref:"dueDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-3",attrs:{"type":"date","label":"Due Date","prepend-icon":"fad fa-calendar","clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.taskData.due_date),callback:function ($$v) {_vm.$set(_vm.taskData, "due_date", $$v)},expression:"taskData.due_date"}},'v-text-field',attrs,false),on))]}}],null,false,2429419179),model:{value:(_vm.dueDateMenu),callback:function ($$v) {_vm.dueDateMenu=$$v},expression:"dueDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"primary"},on:{"input":function($event){_vm.dueDateMenu = false}},model:{value:(_vm.taskData.due_date),callback:function ($$v) {_vm.$set(_vm.taskData, "due_date", $$v)},expression:"taskData.due_date"}})],1):_vm._e(),(!_vm.lockedFields.complete_date)?_c('v-menu',{ref:"completeDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-3",attrs:{"type":"date","label":"Complete Date","prepend-icon":"fad fa-calendar","clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.taskData.complete_date),callback:function ($$v) {_vm.$set(_vm.taskData, "complete_date", $$v)},expression:"taskData.complete_date"}},'v-text-field',attrs,false),on))]}}],null,false,4215179755),model:{value:(_vm.completeDateMenu),callback:function ($$v) {_vm.completeDateMenu=$$v},expression:"completeDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"primary"},on:{"input":function($event){_vm.completeDateMenu = false}},model:{value:(_vm.taskData.complete_date),callback:function ($$v) {_vm.$set(_vm.taskData, "complete_date", $$v)},expression:"taskData.complete_date"}})],1):_vm._e()]:_vm._e(),_c('meta-input-list',{ref:"metaFieldList",attrs:{"model":_vm.taskData,"model-type":"Task","disabled":_vm.saving}})],2):_vm._e(),_c('v-card-actions',[(!_vm.showAllFields && !_vm.fieldsLocked('due_date', 'complete_date'))?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showAllFields=true}}},[_vm._v("Show All Fields")]):_vm._e(),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"mr-3",attrs:{"disabled":_vm.saving},on:{"click":function($event){return _vm.modal.trigger('close')}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.saving},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.taskId ? 'Update' : 'Create')+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }